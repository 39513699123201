<template>
    <div class="card">
        <div class="card-header">
            <p class="card-title"> Autofacturación Operarios</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-4">
                    <div style="display:flex; margin-bottom: 1rem ; flex-direction: column;">
                        <label for="opers">Operarios</label>
                        <Dropdown id="opers" v-model="selectedprovoper" :options="provoper" optionLabel="label" optionGroupLabel="label" optionGroupChildren="items" placeholder="Seleccione operario/proveedor"/>
                    </div>
                    <div class="form-group">
                        <label for="empre">Empresas</label>
                        <select v-model="empresaselec" class="form-control" name="" id="empr">
                            <option value="">Todas</option>
                            <option v-for="empr in empresas" :key="empr.id" :value="empr.id">{{ empr.nombre_empresa }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="inicio">Fecha Inicio</label>
                        <input :disabled="inicioBloqueado" class="form-control" v-model="fechaInicio" type="date">
                    </div>
                    <div class="form-group">
                        <label for="inicio">Fecha Final</label>
                        <input :disabled="finBloqueado" class="form-control" v-model="fechaFin" type="date">
                    </div>
                </div>
                <div class="col-md-1">
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="inicio">Fecha Factura</label>
                        <input class="form-control" v-model="fechaFactura" type="date">
                    </div>                    
                    <div class="form-group">
                        <label for="inicio">Nº Factura</label>
                        <input :disabled="nFacturaBloqueado" class="form-control" v-model="nFactura" type="number">
                    </div>
                </div>
                <div class="col-md-1">
                </div>
                <div class="col-md-1" style="align-items: end;justify-content: end; display: flex;">
                    <button @click="cargarPrevio" class="btn btn-primary">Previo</button>
                </div>
            </div>
            <Dialog v-model:visible="previoDialog" modal header="Importar baremo CSV" :style="{ width: '70rem' }">
                <!--<div class="table-responsive">-->
                    <div class="table-responsive">
                        <table class="table table stripped">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Método Factura</th>
                                    <th>Operario/Proveedor</th>
                                    <th>NIF</th>
                                    <th>Total €</th>
                                    <th>Total IVA €</th>
                                    <th><input type="checkbox" v-model="checktodos"></th>
                                </tr>
                            </thead>
                            <tbody v-for="[id, operario] in Object.entries(operarios)" :key="id">
                                <tr>
                                    <td v-if="operario.mostrar" @click="operario.mostrar = !operario.mostrar" ><i class="fas fa-angle-down"></i></td>
                                    <td v-else @click="operario.mostrar = !operario.mostrar" ><i class="fas fa-angle-right"></i></td>
                                    <td>{{ operario.metodo }}</td>
                                    <td>{{ operario.operario }}</td>
                                    <td>{{ operario.codigo}}</td>
                                    <td>{{ operario.importe_total }}</td>
                                    <td>{{ operario.importe_iva }}</td>
                                    <td><input type="checkbox" @change.stop="cambiocheck(operario)" v-model="operario.check"></td>
                                </tr>
                                <tr v-if="operario.mostrar">
                                    <td>

                                    </td>
                                    <td colspan="5">
                                        <div class="table-responsive">
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Nombre</th>
                                                        <th>Expediente</th>
                                                        <th>Total</th>
                                                        <th>Total + IVA</th>
                                                        <th>Fecha ini.</th>
                                                        <th>Fecha fin</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="[id, trabajo] in Object.entries(operario.trabajos)" :key="id">
                                                        <td>{{ trabajo.cliente }}</td>
                                                        <td>{{ trabajo.expediente }}</td>
                                                        <td>{{ trabajo.importe_total }}</td>
                                                        <td>{{ trabajo.importe_iva }}</td>
                                                        <td>{{ trabajo.fecha_inicio }}</td>
                                                        <td>{{ trabajo.fecha_fin }}</td>
                                                        <td><input type="checkbox" @change="cambiochecktr(operario,trabajo)" v-model="trabajo.check"></td>
                                                    </tr>
                                                </tbody>                                    
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="display: flex; flex-direction: row;">
                        <span style="font-weight: bold;">TOTAL:</span>
                        <span>{{ total }}</span>
                        <span style="margin-left: 15px; font-weight: bold;">TOTAL CON IVA:</span>
                        <span> {{ total_iva }}</span>
                    </div>
                    <div style="display: flex; justify-content: end; padding-right: 30px; gap: 10px;">
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;">Exportar XLS</button>
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="generar()">Generar</button>
                        <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="previoDialog = false">Cerrar</button>
                    </div>
                <!--</div>-->
            </Dialog>
        </div>
    </div>
    <div class="card">
        <div class="card-body">
            <table class="table table-responsive">
                <thead>
                    <tr>
                        <th>Nº Factura</th>
                        <th>Fecha inicio</th>
                        <th>Fecha fin</th>
                        <th>Opeario</th>
                        <th>Empresa</th>
                        <th>Pagada</th>
                        <th>Exportada</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="display: flex; flex-flow: row wrap;">
                            <input type="numbre" class="form-control" v-model="añoInicio">/
                            <input type="number" v-model="añoActual" class="form-control">
                        </td>
                        <td>
                            <input class="form-control" type="date" v-model="fechaInicioFilt">
                        </td>
                        <td>
                            <input class="form-control" type="date" v-model="fechaFinFilt">
                        </td>
                        <td>
                            <input type="text" class="form-control" v-model="operarioFilt">
                        </td>
                        <td>
                            <select v-model="empresaSelecFilt" class="form-control" name="" id="emprr">
                                <option value="">Todas</option>
                                <option v-for="empr in empresas" :key="empr.id" :value="empr.id">{{ empr.nombre_empresa }}</option>
                            </select>
                        </td>
                        <td>
                            <select v-model="pagadaFilt" class="form-control" name="" id="pag">
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </td>
                        <td>
                            <select v-model="exportadaFilt" class="form-control" name="" id="exp">
                                <option value="">--</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        </td>
                        <td style="display: flex; flex-flow: row wrap;">
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;"><i class="fas fa-search"></i></button>
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;">Exportar XLS</button>
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="generar()">Generar</button>
                            <button class="btn btn-sm btn-light" style="border: 1px solid grey;" @click="previoDialog = false">Cerrar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import Dropdown from 'primevue/dropdown';
export default{
    data(){
        return{
            provoper:[],
            selectedprovoper:{label: 'Todos', value: ''},
            empresas:[],
            empresaselec:'',
            inicioBloqueado:false,
            finBloqueado:false,
            fechaInicio:'',
            fechaInicioFilt:'',
            fechaFinFilt:'',
            operarioFilt:'',
            empresaSelecFilt:'',
            fechaFin:'',
            fechaFactura:'',
            nFactura:0,
            nFacturaBloqueado:true,
            previoDialog:false,
            autofacturas:[],
            operarios:[],
            checktodos :true,
            total:0,
            totalgeneral:0,
            total_iva:0,
            total_ivageneral:0,
            añoInicio:'',
            añoActual:'',
            pagadaFilt:'',
            exportadaFilt:'',
        }
    },
    components:{
        Dropdown,
    },
    methods:{
        async generar(){
            const api = new PwgsApi();
            var expedientes = [];
            for(let operario of Object.values(this.operarios)){        
                for(let trabajo of Object.values(operario.trabajos)){
                    if(trabajo.check){
                        expedientes.push(trabajo.seleccionable);
                    }
                }                
            }
            var body = {fecha_inicio: this.fechaesp(this.fechaInicio), fecha_fin: this.fechaesp(this.fechaFin), fecha_factura: this.fechaesp(this.fechaFactura)};
            if(this.empresaselec != ''){
                body.id_empresa=this.empresaselec;
            }
            body.numero = this.nFactura;
            body.expedientes = expedientes;
            try{
                const resp = await api.post('facturacion-operarios/facturar', body);
                console.log(resp);
                this.$toast.add({ severity:'success', summary: 'Generado', detail: 'Factura generada correctamente', life: 2000 });
            }catch(e) {
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }

        },
        cambiochecktr(operario,trabajo){
            if(trabajo.check){
                this.total += Number(trabajo.importe_total);
                this.total = Math.round(this.total * 100) / 100;
                this.total_iva += Number(trabajo.importe_iva);
                this.total_iva = Math.round(this.total_iva * 100) / 100;
                operario.importe_total += Number(trabajo.importe_total);
                operario.importe_total = Math.round(operario.importe_total * 100) / 100;
                operario.importe_iva += Number(trabajo.importe_iva);
                operario.importe_iva = Math.round(operario.importe_iva * 100) / 100;
            }else{
                this.total -= Number(trabajo.importe_total);
                this.total = Math.round(this.total * 100) / 100;
                this.total_iva -= Number(trabajo.importe_iva);
                this.total_iva = Math.round(this.total_iva * 100) / 100;
                operario.importe_total -= Number(trabajo.importe_total);
                operario.importe_total = Math.round(operario.importe_total * 100) / 100;
                operario.importe_iva -= Number(trabajo.importe_iva);
                operario.importe_iva = Math.round(operario.importe_iva * 100) / 100;
            }
        },
        cambiocheck(operario){
            console.log(operario.check);
            if(operario.check){
                for(let trabajo of Object.values(operario.trabajos)){
                    if(!trabajo.check){
                        console.log('entra1',this.total, trabajo.importe_total);
                        trabajo.check = true;
                        this.total += Number(trabajo.importe_total);
                        this.total = Math.round(this.total * 100) / 100;
                        console.log('eltotal', this.total);
                        this.total_iva += Number(trabajo.importe_iva);
                        this.total_iva = Math.round(this.total_iva * 100) / 100;
                    }
                    operario.importe_total = operario.totalgeneral;
                    operario.importe_iva = operario.total_ivageneral;
                }
            }else{
                for(let trabajo of Object.values(operario.trabajos)){
                    if(trabajo.check){
                        console.log('entra2',this.total, trabajo.importe_total);
                        trabajo.check = false;
                        this.total -= trabajo.importe_total;
                        this.total = Math.round(this.total * 100) / 100;
                        this.total_iva -= trabajo.importe_iva;
                        this.total_iva = Math.round(this.total_iva * 100) / 100;
                    }
                    operario.importe_total = 0;
                    operario.importe_iva = 0;
                }
            }
        },
        async cargarPrevio(){
            const api = new PwgsApi();
            var body = {fecha_inicio: this.fechaesp(this.fechaInicio), fecha_fin: this.fechaesp(this.fechaFin), fecha_factura: this.fechaesp(this.fechaFactura)};
            if(!this.selectedprovoper.value == ''){
                var tipo = 'operario';
                if(this.selectedprovoper.value.tipo == 'proveedor'){
                    tipo = 'proveedor';
                }
                body.destinatario= tipo; 
                body.id_destinatario=this.selectedprovoper.value.id;
                body.numero = this.nFactura;
            }
            if(this.empresaselec != ''){
                body.id_empresa=this.empresaselec;
            }
            try{
                var resp = await api.post('facturacion-operarios/previo', body);
                this.operarios = resp.operarios;
                for(let operario of Object.values(this.operarios)){
                    operario.mostrar = false;
                    operario.check = true;
                    operario.totalgeneral = operario.importe_total;
                    operario.total_ivageneral = operario.importe_iva;
                    for(let trabajo of Object.values(operario.trabajos)){
                        trabajo.check = true;
                    }
                }
                this.total = resp.total_base;
                this.totalgeneral = resp.total_base;
                this.total_iva = resp.total_iva;
                this.total_ivageneral = resp.total_iva;
                console.log('opers', this.operarios);
                this.previoDialog = true;
                this.checktodos = true;
            }catch(e){
                this.$toast.add({ severity: 'error', summary: 'Error', detail: e, life: 5000 });
            }
        },
        async obtenerDatos(){
            const api = new PwgsApi();
            const response = await api.get('facturacion-operarios');
            var operarios = response.operarios;
            var proveedores =response.proveedores;
            this.empresas = response.empresas;
            this.inicioBloqueado = response.bloqueos.fechas.inicio == 1;
            this.finBloqueado = response.bloqueos.fechas.fin == 1;
            this.fechaInicio = this.fechaing(response.fechas.inicio);
            console.log('fechaini', this.fechaInicio);
            this.fechaFin = this.fechaing(response.fechas.fin);
            this.fechaFactura = this.fechaing(response.fechas.factura);
            var auxoperarios = {label:'Operarios', items:[]};
            var auxproveedores = {label:'Proveedores', items:[]};
            console.log('opers', operarios, 'provers', proveedores);
            operarios.forEach(operario => {
                auxoperarios.items.push({label:operario.nombre, value:{id:operario.id, tipo:'operario'}});
            });
            proveedores.forEach(proveedor => {
                auxproveedores.items.push({label:proveedor.nombre_proveedor, value:{id:proveedor.id, tipo:'proveedor'}});
            });
            this.provoper.push({label:'Todos', items: [
                {label: 'Todos', value: ''},
            ]});
            this.provoper.push(auxoperarios);
            this.provoper.push(auxproveedores);
        },
        async obtenerNFactura(){
            const api = new PwgsApi();
            var tipo = 'operario';
                if(this.selectedprovoper.value.tipo == 'proveedor'){
                    tipo = 'proveedor';
            }
            const response = await api.post('facturacion-operarios/siguiente-numero',{destinatario: tipo, id_destinatario:this.selectedprovoper.value.id, year: this.anyoActual()});
            this.nFactura = response[0].numero;
        },
        anyoActual(){
            return new Date().getFullYear();
        },
        fechaing(fecha) {
            const [dia, mes, año] = fecha.split("/");
            return `${año}-${mes.padStart(2, '0')}-${dia.padStart(2, '0')}`;
        },
        fechaesp(fecha) {
            const [año, mes, dia] = fecha.split("-");
            return `${dia}/${mes}/${año}`;
        },
    },
    mounted(){
        this.obtenerDatos();
        this.añoActual=this.anyoActual();
    },
    watch:{
        selectedprovoper(value){
            if(value.value == ''){
                this.nFacturaBloqueado = true;
                this.nFactura = 0;
            }else{
                this.nFacturaBloqueado = false;
                this.obtenerNFactura();
            }
        },
        checktodos(value){
            if(value){
                for(let operario of Object.values(this.operarios)){
                    console.log('operentra', operario);
                    operario.check = true;
                    for(let trabajo of Object.values(operario.trabajos)){
                        trabajo.check = true;
                    }
                    operario.importe_total = operario.totalgeneral;
                    operario.importe_iva = operario.total_ivageneral;
                }
                this.total = this.totalgeneral;
                this.total_iva = this.total_ivageneral;
            }else{
                for(let operario of Object.values(this.operarios)){
                    operario.check = false;
                    for(let trabajo of Object.values(operario.trabajos)){
                        trabajo.check = false;
                    }
                    operario.importe_total = 0;
                    operario.importe_iva = 0;
                }
                this.total = 0;
                this.total_iva = 0;
            }
        }
    }
}
</script>